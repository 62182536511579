import ProductImageBuilder from 'lib/product-image-builder';

function generateDemandEmail({ address, email, name, phone }, items, priceDataMap, price, lang, t) {
	
	const { city, country, postalcode, street } = address;

	const groupItems = items.map(({ productSizeCode, quantity }) => `${productSizeCode};${quantity}<br>`).join('')
	

	const productList = items.map(({ productSizeCode, product, quantity }) => {

		const productImageSrc = ProductImageBuilder.makeSrc({ code: product.code, color: product.colorCode }, { view: 'c', height: 80 }, { host: 'https://www.malfini.com' })
		const isInStock = priceDataMap?.[productSizeCode];
		
		return `
		<tr>
			<td align="left" width="100%" style=" margin: 0; padding: 0; line-height: 0px;">
				<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
					<tbody>
						<tr>
							<td align="left" width="15%" style=" margin: 0; padding: 0; line-height: 30px;" rowspan="3">
								<img src="${productImageSrc}">
							</td>
							<td align="left" width="42.5%" style=" margin: 0; padding: 0; line-height: 30px;">
								<strong>${product.code} ${product.name[lang]}</strong>
							</td>
							<td align="right" width="42.5%" style=" margin: 0; padding: 0; line-height: 30px; color: darkgrey;">
								${productSizeCode}
							</td>
						</tr>
							<td align="left" width="42.5%" style=" margin: 0; padding: 0; line-height: 30px;">
								${product.colorCode} - ${product.colorName[lang]} (${product.size[lang]})
							</td>
						<tr style="border-bottom: transparent solid 16px">
							<td align="left" width="42.5%" style=" margin: 0; padding: 0; line-height: 30px;">
								<span style="color: ${isInStock ? 'green' : 'tomato' }">${ t(isInStock ? 'in-stock' : 'out-of-stock') }</span>
							</td>
							<td align="right" width="42.5%" style=" margin: 0; padding: 0; line-height: 30px;">
								${quantity}×
							</td>
						</tr>
					</tbody>
				</table>
			</td>
		</tr>`;
	}).join('')

  return `
<table cellpadding="0" cellspacing="0" width="100%">
	<tbody>
		<tr>
			<td align="center">
				<!-- content table -->
				<table class="fullwidth" align="center" cellpadding="0" cellspacing="0" style="width: 600px; border-collapse: collapse;">
					<tbody>
						<tr>
							<td style="margin: 0; padding: 0;">
								<!-- drop zone -->
								<div id="main" class="" style="" data-original-style="">
									<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; background-color: #ffffff;">
										<tbody>
											<tr>
												<td align="left" width="100%" style="margin: 0; padding: 0px 0;">
													<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
														<tbody>
															<tr>
																<td align="left" width="100%" style=" margin: 0; padding: 0; line-height: 0px;">
																	<a id="dataset-link" href="https://malfini.t.mailkit.eu/mc/VCCQCIIW/WIMNVGVXAVGPUOUENW" target="_blank">
																		<img id="dataset-alt" src="https://u.mailkit.eu/user.fcgi/action,img_local/dc,9563/df%2C365522" alt="MALFINI, a.s." style="max-width: 100%;" border="0">
																	</a>
																	<span></span>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: Arial-Narrow, sans-serif; color: rgb(0, 0, 0); line-height: 30px; font-size: 25px; margin: 0px; padding: 30px 30px 20px; background-color: rgb(255, 255, 255); " class="" aria-multiline="true">
													<strong>${t('header')}</strong>
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: 'Arial Narrow', sans-serif; color: rgb(0, 0, 0); line-height: 30px; font-size: 20px; margin: 0px; padding: 30px 30px 20px; background-color: rgb(255, 255, 255); " class="" aria-multiline="true">${t('description')}</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: Arial, sans-serif; color: rgb(255, 255, 255); font-weight: bold; font-size: 18px; margin: 0px; padding: 10px 0px; background-color: rgb(220, 220, 220); " class="" aria-multiline="true">
													<span style="color:#000000;">&nbsp; &nbsp; &nbsp; ${t('contact')}:</span>
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;"></table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: 'Arial Narrow', sans-serif; color: rgb(0, 0, 0); line-height: 30px; font-size: 18px; margin: 0px; padding: 30px 30px 20px; background-color: rgb(255, 255, 255); " class="" aria-multiline="true">
													<strong>${t('name')}:&nbsp;</strong>${name}<br>
													<strong>${t('email')}:&nbsp;</strong>${email}<br>
													<strong>${t('phone')}:&nbsp;</strong>${phone}<br>
													<strong>${t('street')}:&nbsp;</strong>${street}<br>
													<strong>${t('city')}:&nbsp;</strong>${city}<br>
													<strong>${t('zip-code')}:&nbsp;</strong>${postalcode}<br>
													<strong>${t('country')}:&nbsp;</strong>${country}
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: Arial, sans-serif; color: rgb(255, 255, 255); font-weight: bold; font-size: 18px; margin: 0px; padding: 10px 0px; background-color: rgb(220, 220, 220); " class="" aria-multiline="true">
													<span style="color:#000000;">&nbsp; &nbsp; &nbsp; ${t('demand-items')}:</span>
												</td>
											</tr>
										</tbody>
									</table>
									<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; background-color: #ffffff;">
										<tbody>
											<tr>
												<td align="left" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 30px 30px;">
													<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
														<tbody>
															${productList}
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; background-color: #ffffff;">
										<tbody>
											<tr>
												<td align="left" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 30px 30px;">
													<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
														<tbody>
															${ !!price.total ?
																`<tr>
																	<td align="left" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 0px;">
																		${t('total-without-vat')}
																	</td>
																	<td align="right" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 0px;">
																		${price.total.toFixed(2)}
																	</td>
																</tr>` : ''
															}
															${ !!price.totalWithVat ?
																`<tr>
																	<td align="left" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 0px;">
																		${t('total')}
																	</td>
																	<td align="right" width="100%" style="font-family: Arial-Narrow, sans-serif; margin: 0; padding: 0px;">
																		${price.totalWithVat.toFixed(2)}
																	</td>
																</tr>` : ''
															}
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: 'Arial Narrow', sans-serif; color: rgb(0, 0, 0); line-height: 30px; font-size: 18px; margin: 0px; padding: 30px 30px 20px; background-color: rgb(255, 255, 255); " class="" aria-multiline="true">
													<hr>
													<strong><br>${t('items')}:</strong>
													<br>
													${groupItems}
													<br>
													<strong>${t('group-header')}:</strong><br>
													1) ${t('group-step-1')}<br>
													2) ${t('group-step-2')}<br>
													3)&nbsp;${t('group-step-3', { replace: { link: '<a href="https://malfini.t.mailkit.eu/mc/VCCQCILU/EWSDNMBTXQZHSOTEDO">malfini.com</a>' } })}<br>
													4) ${t('group-step-4')}<br>
													5) ${t('group-step-5')}<br>
												</td>
											</tr>
										</tbody>
									</table>
									<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; background-color: #ffffff;">
										<tbody>
											<tr>
												<td align="center" style="margin: 0; padding: 20px 0 20px 0;">
													<table width="100%" border="0" cellspacing="0" cellpadding="0">
														<tbody>
															<tr>
																<td align="center">
																	<table border="0" cellspacing="0" cellpadding="0">
																		<tbody>
																			<tr>
																				<td align="center" bgcolor="#FFFFFF">
																					<a href="https://malfini.t.mailkit.eu/mc/VCCQCIVP/RPMRJWQOHNEOMBGEWD" target="_blank" style="font-size: 18px; font-family: Arial, sans-serif; color: #FFFFFF; text-decoration: none; text-decoration: none; padding:  16px 30px 14px 30px; background-color: #000000; display: inline-block;">
																						<span>${t('order')}</span>
																					</a>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<table align="center" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
										<tbody>
											<tr>
												<td align="left" style="font-family: 'Arial Narrow', sans-serif; color: rgb(0, 0, 0); line-height: 30px; font-size: 18px; margin: 0px; padding: 30px 30px 20px; background-color: rgb(255, 255, 255); " class="" aria-multiline="true">${t('farewell')}<br>​​​​​​​
													<hr>
												</td>
											</tr>
										</tbody>
									</table>
									<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; background-color: #ffffff;">
										<tbody>
											<tr>
												<td align="left" width="100%" style="margin: 0; padding: 20px 0;">
													<table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
														<tbody>
															<tr>
																<td align="center" width="100%" style=" margin: 0; padding: 0; line-height: 0px;">
																	<a id="dataset-link" href="https://malfini.t.mailkit.eu/mc/VCCQCIVM/QSKRCKRICZKMTLGDCW" target="_blank">
																		<img id="dataset-alt" src="https://u.mailkit.eu/user.fcgi/action,img_local/dc,9563/df%2C365523" alt="MALFINI, a.s." style="max-width: 100%;" border="0">
																	</a>
																	<span></span>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<!-- end drop zone -->
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- end content table -->
				<!-- end main table -->
			</td>
		</tr>
	</tbody>
</table>
`;
}

export default generateDemandEmail;