import { mdiCart, mdiFacebook, mdiHome, mdiInstagram, mdiMagnify, mdiTwitter } from "@mdi/js";
import Icon from "@mdi/react";
import Dropdown from "components/dropdown.component";
import MdiButton from "components/mdi-button.component";
import styles from "./basic.layout.module.css";
import cn from "classnames"
import useApp from "contexts/app.context";
import Link from "components/link.component";
import { useTranslation } from "react-i18next";
import { mainContentCode } from "constants/main-content-code";
import { Link as WouterLink, useLocation } from "wouter";
import { useEffect, useMemo } from "react";
import Badge from "components/badge.component";
import useCart from "contexts/cart.context";
import { useMedia } from "react-use";
import SearchBar from "components/search-bar.component";
import { useState } from "react";

function BasicLayout({ children, fullwidth }) {
	const app = useApp();
	const cart = useCart();
	const { t, i18n } = useTranslation();
	const [ location ] = useLocation();
	const onlyDesktop = useMedia('(min-width: 992px)');
	const [ mobileSearchBarVisible, setMobileSearchBarVisible ] = useState(false);

	function langHref(lang) {
		const [ , store, , ...rest ] = window.location.pathname.split('/');
		const newUrl = `/${store}/${lang}/${rest.join('/')}`;
		return newUrl;
	}

	const logoSrc = useMemo(() => {
		if (app.layout?.design?.logoPath) {
			return process.env.REACT_APP_END_POINT + '/image/' + app.layout?.design?.logoPath;
		} else {
			return '/logo.svg';
		}
	}, [ app ]);

	useEffect(() => {
		setMobileSearchBarVisible(false);
	}, [ location ])

	return (
		<>
			<header>
				<div className={styles.top}>
					<div className={styles.settings}>
						<span className="mr-2">{ t('language') }</span>
						<Dropdown
							overlay={
								<div>
									{
										app.layout?.languages.map(l => <WouterLink key={l} href={langHref(l)}><a className="m-1" style={{ display: 'block' }} href={langHref(l)}>{ l }</a></WouterLink>)
									}
								</div>
							}
						>
						{ i18n.language }
						</Dropdown>
						<span className="mx-2">{ t('currency') }</span>
						<Dropdown
							overlay={
								<div>
									{ app.layout?.currencies.map(currency => <div className="m-1" onClick={() => app.setCurrency(currency)} key={currency}>{ currency }</div>) }
								</div>
							}
						>
							{ app.currency }
						</Dropdown>
					</div>
					{
						onlyDesktop && (
							<ul className={styles.socials}>
								{ app.layout?.socialMedia?.facebookUrl && <li><a href={`https://cs-cz.facebook.com/${app.layout?.socialMedia?.facebookUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiFacebook} /></a></li> }
								{ app.layout?.socialMedia?.twitterUrl && <li><a href={`https://twitter.com/${app.layout?.socialMedia?.twitterUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiTwitter} /></a></li> }
								{ app.layout?.socialMedia?.instagramUrl && <li><a href={`https://www.instagram.com/${app.layout?.socialMedia?.instagramUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiInstagram} /></a></li> }
							</ul>
						)
					}
				</div>
				<div className={styles.main}>
					<Link href="/">
						<img className={styles.logo} src={logoSrc} alt="logo" />
					</Link>
					{ onlyDesktop ? <SearchBar style={{ marginLeft: 84 }} /> : null }
					<div style={{ flex: 1 }}></div>
					<nav className={styles.navigation}>
						{ !onlyDesktop && <MdiButton path={mdiMagnify} type="primary" onClick={() => setMobileSearchBarVisible(p => !p)} /> }
						<Link href="/">
							<MdiButton path={mdiHome} type="primary" />
						</Link>
						<Link href="/catalog" className={cn({ [styles.active] : window.location.pathname.includes(`/${i18n.language}/catalog`) })}>
							{ t('catalog-link') }
						</Link>
						{
							app.layout?.articles.map(a => (
								<Link key={a.code} href={`/article/${a.code}`} className={cn({ [styles.active] : window.location.pathname.includes(`/article/${a.code}`) })}>
									{ a.title[i18n.language] }
								</Link>
							))
						}
						{
							cart && (
								<Link href="/cart">
									<Badge value={cart.size}>
										<MdiButton path={mdiCart} type="primary" />
									</Badge>
								</Link>
							)
						}
					</nav>
				</div>
				{ mobileSearchBarVisible && <SearchBar style={{ padding: '1rem' }} /> }
			</header>
			<main className={cn(styles.content, fullwidth && styles.content__full_width)}>{ children }</main>
			<footer className={styles.footer}>
				<div className={styles.footer_sections}>
					<div className="p-1">
						<h3>{ t('about-us') }</h3>
						<div dangerouslySetInnerHTML={{ __html: app.layout?.mainContent.find(a => a.code === mainContentCode.ABOUT_US)?.value[i18n.language] }}></div>
					</div>
					<div className="p-1">
						<h3>{ t('links') }</h3>
						<div>
							<ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
								{ app.layout?.articles.map(a => <li key={a.code}><Link href={`/article/${a.code}`}>{ a.title[i18n.language] }</Link></li>) }
							</ul>
						</div>
					</div>
					<div className="p-1">
						<h3>{ t('contact') }</h3>
						<div dangerouslySetInnerHTML={{ __html: app.layout?.mainContent.find(a => a.code === mainContentCode.CONTACTS)?.value[i18n.language] }}></div>
					</div>
				</div>
				<ul className={styles.socials}>
					{ app.layout?.socialMedia?.facebookUrl && <li><a href={`https://cs-cz.facebook.com/${app.layout?.socialMedia?.facebookUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiFacebook} /></a></li> }
					{ app.layout?.socialMedia?.twitterUrl && <li><a href={`https://twitter.com/${app.layout?.socialMedia?.twitterUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiTwitter} /></a></li> }
					{ app.layout?.socialMedia?.instagramUrl && <li><a href={`https://www.instagram.com/${app.layout?.socialMedia?.instagramUrl}`} target="_blank" rel="noopener noreferrer"><Icon path={mdiInstagram} /></a></li> }
				</ul>
				<hr/>
				<div className={styles.copyright}>
					2023 © MALFINI, a.s.
				</div>
			</footer>
		</>
	)
}

export default BasicLayout;