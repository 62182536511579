import Dropdown from "components/dropdown.component";
import useApp from "contexts/app.context";
import useCart from "contexts/cart.context";
import ProductColorBuilder from "lib/product-color-builder";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./order-table.module.css";
import cn from "classnames";
import { useMemo, useState } from "react";
import Button from "components/button.component";

function Price({ price: { value, valueWithVat } }) {
	const { t } = useTranslation('detail');
	const { currency } = useApp();
	return (
		<div>
			{ value && <div><strong>{ value }</strong><span style={{ marginLeft: '0.25rem' }}>{ currency }</span></div> }
			{ valueWithVat && (<><strong>{ valueWithVat}</strong><span style={{ marginLeft: '0.25rem' }}>{ currency }</span><span style={{ fontSize: '0.8rem', marginLeft: '0.25rem' }}>{ t('vat') }</span></>) }
		</div>
	)
}

function OrderTable({ color, product, className }) {

	
	const { t, i18n } = useTranslation('detail');
	const app = useApp();
	const cart = useCart();
	
	const { data } = useSWR(() => product && app.currency && `/api/v2/${app.store}/product/${product.seoName}/price/${app.currency}?colorCode=${color.seoName}`);

	const [ quantityChanges, setQuantityChanges ] = useState(null);
	
	const limits = useMemo(() => {
		if (data && data[0]) {
			return data[0].prices.map(p => p.limit);
		}
		return [];
	}, [ data ])

	const rightColumnSize = useMemo(() => {
		let size = limits.length;
		if (app.layout?.price.showPrice === "WithoutPrice") {
			return 0;
		}
		return size;
	}, [ app.layout?.price.showPrice, limits ])


	if (!product || !color) {
		return null;
	}

	function handleChangeQuantity(e, availability) {
		const quantity = Math.max(parseInt(e.target.value) || 0, 0);
		const p = {
			productSizeCode: availability.productSize,
			product: {
				code: product.code,
				name: product.name,
				size: availability.size,
				colorName: color.name,
				colorCode: color.code
			}
		}
		const newChanges = { ...(quantityChanges ?? {}) }
		newChanges[p.productSizeCode] = { product: p, quantity }
		setQuantityChanges(newChanges);
	}
	
	function handleSend() {
		cart.setMultiple(Object.values(quantityChanges));
		setQuantityChanges(null);
	}

	return (
		<div className={cn(styles.wrapper, className)}>
			<h3>{ t('order-table-header') }</h3>
			<div className={styles.table_wrapper}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th colSpan="3">
								<div className={styles.color}>
									{ color && <img src={ProductColorBuilder.get(color)} alt=""/> }
									<span>{ color?.name[i18n.language] }</span>
								</div>
							</th>
							{
								!!rightColumnSize && <th colSpan={rightColumnSize}>
									{
										(app.layout?.currencies.length > 1) && (
											<div className={styles.currency}>
												<span className="mr-1">{ t('currency') }</span>
												<Dropdown
													overlay={
														<div className="my-1">
															{ app.layout?.currencies.map(c => <div key={c} className="m-1" onClick={() => app.setCurrency(c)}>{ c }</div>) }
														</div>
													}
												>
													{ app.currency }
												</Dropdown>
											</div>
										)
									}
							</th>
						}
						{ cart && <th></th> }
						</tr>
						<tr>
							<th>{ t('number') }</th>
							<th>{ t('sizes') }</th>
							<th>{ t('availability') }</th>
							{
								rightColumnSize === 4 && (
									<>
										{ limits.map(limit => <th key={limit}>{ t('from-{{value}}-pieces', { value: limit }) }</th>) }
									</>
								)
							}
							{ rightColumnSize === 1 && <th></th> }
							{ cart && <th></th> }
						</tr>
					</thead>
					<tbody>
						{
							data?.map(r => {
								return (
									<tr key={r.productSize}>
										<td>{ r.productSize }</td>
										<td>{ r.size[i18n.language] }</td>
										<td>{ r.availability } </td>
										{ r.prices.map((price, i) => <td key={i}><Price price={price} /></td>) }
										{ cart && <td className={styles.add_cell}>
											<input
												className={styles.input}
												value={(quantityChanges?.[r.productSize]?.quantity ?? cart.items[r.productSize]?.quantity) ?? ''}
												onChange={e => handleChangeQuantity(e, r)}
												onWheelCapture={e => e.target.blur()}
												placeholder="0"
												type="number"
												min={0}
											/>
										</td> }
									</tr>
								)
							})
						}
					</tbody>
				</table>
				<div className="mt-2 fx">
					{ !!cart && <Button type="primary" className="ml" onClick={handleSend} disabled={!quantityChanges}>{ t('add-to-cart') }</Button> }
				</div>
			</div>
		</div>
	)
}

export default OrderTable;