import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { SWRConfig } from 'swr';

import Css from './lib/css';
import fetcher from 'lib/fetcher';
import ProductColorBuilder from 'lib/product-color-builder';
import { AppContextProvider } from 'contexts/app.context';
import { init } from './i18n';
import { DialogContextProvider } from 'contexts/dialog.context';
import { CartContextProvider } from 'contexts/cart.context';

Css
  .createDefault({ max: 6, step: 0.5, unit: 'rem' })
  .createFlexBreak('992px')
  .createVh()
  .createText()

function parseUrl() {
	let [ , storeString, langString ] = window.location.pathname.split('/');
  const store = storeString || 'default';
	const lang = langString || navigator.language.split('-')[0];
	if (!storeString || !langString) {
		console.log('Redirecting...');
		const newPathname = `/${store}/${lang}`;
		window.history.replaceState(undefined, undefined, newPathname);
  }
  return lang;
}

async function main() {
  const lang = parseUrl();
  await ProductColorBuilder.init();
  await init(lang);
  ReactDOM.render(
    <React.StrictMode>
      <SWRConfig
        value={{
          fetcher: fetcher,
          revalidateOnFocus: false,
        }}
      >
        <AppContextProvider>
          <DialogContextProvider>
            <CartContextProvider>
              <App />
            </CartContextProvider>
          </DialogContextProvider>
        </AppContextProvider>
      </SWRConfig>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

main();