import Button from "components/button.component";
import Modal from "components/modal.component";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const DESTROY_TIMEOUT = 1000;

/**
 * @typedef DialogContextState
 * @type {object}
 * @property {(modalComponent: Component, props: any) => string} show - return id of modal
 * @property {(id: string) => void} close
 * @property {(message: string, onSubmit: () => boolean) => void} confirm
 * @property {(message: string) => void} message
*/

/** @type {DialogContextState} */
const initState = {};

const DialogContext = createContext(initState);

export function DialogContextProvider({ children }) {

	const [ modals, setModals ] = useState([]);

	function show(ModalComponent, props) {
		const id = Date.now().toString();
		const modal = {
			id,
			Component: ModalComponent,
			props: props,
			visible: true
		}
		setModals(p => ([ ...p, modal ]));
		return id;
	}

	function remove(id) {
		setModals(p => p.filter(m => m.id !== id));
	}

	function close(id) {
		setModals(p => p.map(m => {
			if (m.id === id) {
				setTimeout(() => {
					remove(id)
				}, DESTROY_TIMEOUT);
				return { ...m, visible: false }
			}
			return m;
		}))
	}

	function confirm(message, onSubmit) {
		show(ConfirmDialog, { message, onSubmit });
	}

	function message(message) {
		show(MessageDialog, { message });
	}

	return (
		<DialogContext.Provider
			value={{
				show,
				close,
				confirm,
				message
			}}
		>
			{ children }
			{ modals.map(({ Component, visible, id, props }) => <Component key={id} open={visible} close={() => close(id)} {...props} />) }
		</DialogContext.Provider>
	)
}

export default function useDialog() {
	const dialog = useContext(DialogContext);
	return dialog;
}

function ConfirmDialog({ open, close, message, onSubmit }) {

	const { t } = useTranslation();

	async function handleClick(result) {
		await onSubmit(result);
		close();
	}

	return (
		<Modal open={open}>
			<Modal.Content>
				{ message }
			</Modal.Content>
			<Modal.Footer>
				<div className="fx gap-1">
					<Button className="ml" onClick={() => handleClick(false)}>{ t('cancel') }</Button>
					<Button type="primary" onClick={() => handleClick(true)}>{ t('ok') }</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

function MessageDialog({ open, close, message }) {

	const { t } = useTranslation();

	return (
		<Modal open={open}>
			<Modal.Content>
				{ message }
			</Modal.Content>
			<Modal.Footer>
				<div className="fx gap-1">
					<Button className="ml" onClick={() => close()}>{ t('ok') }</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}