import { mdiClose, mdiMinus, mdiPlus } from "@mdi/js";
import cn from "classnames";
import MdiButton from "components/mdi-button.component";
import useApp from "contexts/app.context";
import useCart from "contexts/cart.context";
import useDialog from "contexts/dialog.context";
import findPrice from "lib/find-price";
import ProductImageBuilder from "lib/product-image-builder";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";

import styles from "./product-cart.module.css";

function ProductCart({ product, productSizeCode, quantity, availability }) {
	const app = useApp();
	const { t, i18n } = useTranslation('cart');
	const cart = useCart();
	const lang = i18n.language;
	const isInStock = availability?.availability !== "Unavailable";
	const dialog = useDialog();

	function handleChangeQuantity(e) {
		const quantity = Math.max(parseInt(e.target.value) || 0, 0);
		if (quantity === 0) {
			handleRemove();
		} else {
			cart.changeQuantity(productSizeCode, quantity)
		}
	}

	function handleRemove() {
		dialog.confirm(
			t('remove-item-from-cart'),
			(res) => {
				if (res) {
					cart.remove(productSizeCode)
				}
			}
		)
	}

	function handleQuantityStep(step) {
		if (quantity + step < 1) {
			handleRemove();
		} else {
			cart.changeQuantity(productSizeCode, quantity + step)
		}
	}

	const priceTotal = useMemo(() => {
		if (availability) {
			const price = findPrice(availability.prices, quantity);
			return quantity * (price?.valueWithVat || price?.value || 0);
		}
	}, [ quantity, availability ])

	return (
		<div className={cn(styles.wrapper, { [styles.wrapper__without_price]: !priceTotal })}>
			<img className={styles.thumb} {...ProductImageBuilder.makeSrcSet({ code: product.code, color: product.colorCode }, { view: 'c', height: 80 })} alt="" />
			<Link href={app.href(`/${product.code}/${product.colorCode}`)}><div className={styles.title}>{ product.code} { product.name[lang] }</div></Link>
			<div className={styles.code}>{ productSizeCode }</div>
			<div className={styles.desc}>
				<div>
					<span>{ product.colorCode } - { product.colorName[lang] }</span>
					<span className="ml-1">({ product.size[lang] })</span>
				</div>
				<div style={{ color: isInStock ? 'green' : 'tomato' }}>{ t(isInStock ? 'in-stock' : 'out-of-stock') }</div>
			</div>
			<div className={styles.bar}>
				<div className={styles.quantity}>
					<MdiButton path={mdiClose} onClick={handleRemove} type="dangerous" />
					<input
						className={styles.quantity_input}
						value={quantity}
						onChange={handleChangeQuantity}
						placeholder="0"
						type="number"
						min={0}
					/>
					<MdiButton path={mdiMinus} onClick={() => handleQuantityStep(-1)} />
					<MdiButton path={mdiPlus} onClick={() => handleQuantityStep(1)} />
				</div>
				{ !!priceTotal && <div className={styles.price}>{ priceTotal.toFixed(2) } { app.currency }</div> }
			</div>
		</div>
	)
}

export default ProductCart;