import styles from "./checkbox.module.css";
import cn from "classnames";

function Checkbox({ id, children, isSwitch, className, ...rest }) {
	return (
		<span className={className}>
			<input className={cn(styles.input, isSwitch && styles.switch)} type="checkbox" id={id} {...rest}/>
			<label htmlFor={id}>{ children }</label>
		</span>
	)
}

export default Checkbox;