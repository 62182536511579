import useApp from "contexts/app.context";
import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "react-use";

/**
 * @typedef Product
 * @type {object}
 * @property {string} code
 * @property {{ [lang: string]: string }} name
 * @property {{ [lang: string]: string }} size
 * @property {{ [lang: string]: string }} colorName
 * @property {string} colorCode
 */

/**
 * @typedef Availability
 * @type {object}
 * @property {string} availability
 * @property {{ limit: number, value?: number, valueWithVat: number }[]} prices
 */

/**
 * @typedef CartContextState
 * @type {object}
 * @property {(product: any, quantity: number) => void} set
 * @property {(product: any) => void} remove
 * @property {(productSizeCode: string, quantity: number) => void} changeQuantity
 * @property {() => void} clear
 * @property {number} size
 * @property {{ [productSizeCode: string]: { product: Product, availability: Availability, quantity: number, productSizeCode: string } }} items
 */

/** @type {CartContextState} */
const initState = {};

const CartContext = createContext(initState);

const DEFAULT_STATE = {
	items: {}
}

export function CartContextProvider({ children }) {

	const app = useApp();

	const [ state, setState ] = useLocalStorage('CART', DEFAULT_STATE);

	const size = useMemo(() => {
		if (state) {
			return Object.values(state.items).length;
		}
	}, [ state ])

	function set(product, quantity) {
		const newItems = { ...state.items };
		if (quantity > 0) {
			newItems[product.productSizeCode] = {
				...product,
				quantity
			}
		} else {
			delete newItems[product.productSizeCode];
		}
		setState({ ...state, items: newItems })
	}

	function setMultiple(data) {
		const newItems = { ...state.items };
		data.forEach(({ product, quantity }) => {
			if (quantity > 0) {
				newItems[product.productSizeCode] = {
					...product,
					quantity
				}
			} else {
				delete newItems[product.productSizeCode];
			}
		});
		setState({ ...state, items: newItems })
	}

	function changeQuantity(productSizeCode, quantity) {
		if (state.items[productSizeCode]) {
			const newItems = { ...state.items };
			if (quantity > 0) {
				newItems[productSizeCode].quantity = quantity;
			} else {
				delete newItems[productSizeCode];
			}
			setState({ ...state, items: newItems })
		}
	}

	function remove(productSizeCode) {
		const newItems = { ...state.items };
		delete newItems[productSizeCode];
		setState({ ...state, items: newItems })
	}

	function clear() {
		setState(DEFAULT_STATE);
	}

	const contextValue = {
		items: state?.items,
		set,
		setMultiple,
		remove,
		clear,
		size,
		changeQuantity
	}

	return (
		<CartContext.Provider
			value={app.layout.isDemandEnabled ? contextValue : false}
		>
			{ children }
		</CartContext.Provider>
	)
}

export default function useCart() {
	const cart = useContext(CartContext);
	return cart;
}