import Link from "components/link.component";
import Loader from "components/loader.component";
import useApp from "contexts/app.context";
import BasicLayout from "layouts/basic.layout";
import ProductColorBuilder from "lib/product-color-builder";
import { scrollToTop } from "lib/scroll";
import Gallery from "pages/detail.page/components/gallery.component";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import OrderTable from "./components/order-table.components";
import styles from "./detail.page.module.css";
import Buttons from "pages/detail.page/components/buttons.component";

function DetailPage({ params: { productCode, colorCode } }) {

	const { store } = useApp();
	const { i18n } = useTranslation();
	const { data: product } = useSWR(`/api/v2/${store}/product/${productCode}/detail?colorCode=${colorCode}`)

	const color = useMemo(() => {
		return product?.colors.find(c => c.seoName === colorCode);
	}, [ colorCode, product ])

	useEffect(() => {
		scrollToTop();
	}, [ productCode ])

	if (!product) {
		return (
			<BasicLayout>
				<center style={{ height: '100%' }}>
					<Loader />
				</center>
			</BasicLayout>
		)
	}

	return (
		<BasicLayout>
			<div className={styles.wrapper}>
				<Gallery product={product} colorCode={colorCode} />
				<div className={styles.aside}>
					<h1 className={styles.header}>{ product?.name[i18n.language] }<span>{ product?.code }</span></h1>
					<h2 className={styles.subheader}>{	product?.subName[i18n.language]	}</h2>
					<div className="fx responsive">
						<div>
							<div className={styles.specification} dangerouslySetInnerHTML={{ __html: product?.specification[i18n.language] }} />
							<div className={styles.description} dangerouslySetInnerHTML={{ __html: product?.description[i18n.language] }} />
							<div className={styles.description}>
								<ul>
									{
										product.attributes
											.filter(a => !a.icon)
											.map((a, i) => <li key={i}><strong>{a.title[i18n.language]}:</strong><span className="ml-1">{a.text[i18n.language]}</span></li>)
									}
								</ul>
							</div>
						</div>
							<ul className={styles.icons}>
								{
									product.attributes
									.filter(a => a.icon)
										.map((a, i) => (
											<li key={i} className={styles.attribute}>
												<img src={process.env.REACT_APP_END_POINT + '/image/' + a.icon} alt={a.title[i18n.language]} />
												<span>{ a.text[i18n.language] }</span>
											</li>
										))
									}
							</ul>
						</div>
					<hr/>
					<div className={styles.colors}>
						{
							product?.colors.map(c => (
								<Link href={`/${product?.seoName}/${c.seoName}`} key={c.code} className={styles.color} tooltip={c.name[i18n.language]}>
									<img src={ProductColorBuilder.get(c)} alt=""/>
									<span style={{ color: c.textColor }}>{ c.code }</span>
								</Link>
							))
						}
					</div>
					<hr/>
					<Buttons product={product} />
				</div>
			</div>
			<OrderTable color={color} product={product} className="mb-3" />
		</BasicLayout>
	)
}

export default DetailPage;