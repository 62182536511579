import Button from "components/button.component";
import Carousel from "components/carousel.component";
import Link from "components/link.component";
import { mainContentCode } from "constants/main-content-code";
import useApp from "contexts/app.context";
import BasicLayout from "layouts/basic.layout";
import { useTranslation } from "react-i18next";

function HomePage() {
	const { t, i18n } = useTranslation('home');
	const app = useApp();
	
	return (
		<BasicLayout>
			<Carousel>
				{
					app.layout?.banners
						.filter(b => b.language === i18n.language)
						.map(b => {
							const src = process.env.REACT_APP_END_POINT + '/image/' + b.path;
							if (b.link) {
								return (
									<a href={b.link} key={b.path}>
										<img style={{ width: '100%' }} src={src} alt="banner" />
									</a>
								)
							} else {
								return <img src={src} key={b.path} alt="banner" />
							}
						})
				}
			</Carousel>
			<div className="my-4 mx">
				<Link href="/catalog" style={{ display: 'block', margin: 'auto', width: 'fit-content' }}>
					<Button type="primary">{ t('go-to-catalog') }</Button>
				</Link>
			</div>
			<div dangerouslySetInnerHTML={{ __html: app.layout?.mainContent.find(a => a.code === mainContentCode.HOME_PAGE)?.value[i18n.language] }}></div>
		</BasicLayout>
	)
}

export default HomePage;