class ProductImageBuilder {
	static makeSrc({ code, color }, { view, width, height, ration = 1 }, { host } = {}) {

		const endPoint = host || process.env.REACT_APP_END_POINT;

		if (height && width) {
			const actualWidth = width * ration;
			const actualHeight = height + ration;
			return endPoint + `/image/product/${code}/${code}_${color}_${view}~w${actualWidth}h${actualHeight}.jpg`
		}
		if (width) {
			const actualWidth = width * ration;
			return endPoint + `/image/product/${code}/${code}_${color}_${view}~w${actualWidth}.jpg`
		}
		if (height) {
			const actualHeight = height * ration;
			return endPoint + `/image/product/${code}/${code}_${color}_${view}~h${actualHeight}.jpg`
		}
		throw new Error('At least one dimension must be specified!');
	}

	static makeSrcSet(product, { view, width, height, maxPixelRation = 5 }) {
		const paths = [];
		for (let pixelRation = 1; pixelRation < maxPixelRation; pixelRation++) {
			paths.push(`${ProductImageBuilder.makeSrc(product, { width, view, height, ration: pixelRation })} ${pixelRation}x`);
		}
		return {
			src: ProductImageBuilder.makeSrc(product, { view, width, height }),
			srcSet: paths.join(', ')
		}
	}
}

export default ProductImageBuilder;