function findPrice(prices, quantity) {
	let last = prices[0];
	for (const price of prices) {
		if (quantity < price.limit) {
			break;
		} else {
			last = price;
		}
	}
	return last;
}

export default findPrice;