let cancelled = false;

export function scrollToTop(animate = false) {
	if (!animate) {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		window.scroll(0, 0);
	} else {
		cancelled = false;
		move();
	}
	return () => cancelled = true;
}

function move() {
	if (cancelled) {
		return;
	}
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
		window.requestAnimationFrame(() => move());
		window.scrollTo(0, c - c / 16);
	}
}