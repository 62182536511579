import useApp from "contexts/app.context";
import { useMemo } from "react";
import { Link as WouterLink } from "wouter";

function Link({ href, children, lang, ...rest }) {
	const { href: hrefBuilder } = useApp();

	const myHref = useMemo(() => hrefBuilder(href !== '/' ? href : '', lang), [ href, hrefBuilder, lang ])

	return (
		<WouterLink href={myHref}>
			<a href={myHref} {...rest}>{ children }</a>
		</WouterLink>
	)
}

export default Link;