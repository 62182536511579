import styles from "./checkbox-image.module.css";
import cn from "classnames";

function CheckboxImage({ children, className, id, src, ...rest }) {

	return (
		<span className={cn(styles.wrapper, className)}>
			<input className={styles.input} type="checkbox" name="" id={id} {...rest} />
			<label className={styles.label} htmlFor={id}>
				<img src={src} alt="" />
			</label>
		</span>
	)
}

export default CheckboxImage;