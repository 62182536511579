import Button from "components/button.component";
import Modal from "components/modal.component";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import styles from "./order-form.dialog.module.css";
import cn from "classnames";

function OrderFormDialog({ open, close, onSubmit }) {

	const { t } = useTranslation('cart');

	const { register, handleSubmit: registerSubmit } = useForm();

	async function handleSubmit(data) {
		try {
			await onSubmit(data);
			close();
		} catch { }
	}

	return (
		<form onSubmit={registerSubmit(handleSubmit)}>
			<Modal open={open} className={styles.modal}>
				<Modal.Header onClose={close}>{ t('dialog-header') }</Modal.Header>
				<Modal.Content>
					<div className={cn(styles.field, 'mt-1')}>
						<label htmlFor="name">{ t('dialog-form-name') }</label>
						<input type="text" {...register('name', { required: true })} placeholder="Joe Doe" />
					</div>
					<div className={styles.field}>
						<label htmlFor="email">{ t('dialog-form-email') }</label>
						<input type="email" {...register('email', { required: true })} placeholder="joe.doe@email.com" />
					</div>
					<div className={styles.field}>
						<label htmlFor="phone">{ t('dialog-form-phone') }</label>
						<input type="tel" {...register('phone', { required: true })} />
					</div>
					<div className={styles.field}>
						<label htmlFor="address.street">{ t('dialog-form-street') }</label>
						<input {...register('address.street', { required: true })} />
					</div>
					<div className={styles.field}>
						<label htmlFor=".address.city">{ t('dialog-form-city') }</label>
						<input {...register('address.city', { required: true })} />
					</div>
					<div className={styles.field}>
						<label htmlFor="address.postalcode">{ t('dialog-form-postcode') }</label>
						<input {...register('address.postalcode', { required: true })} />
					</div>
					<div className={cn(styles.field, 'mb-2')}>
						<label htmlFor="address.country">{ t('dialog-form-country') }</label>
						<input {...register('address.country', { required: true })} />
					</div>
					
				</Modal.Content>
				<Modal.Footer>
					<div className="fx gap-1">
						<Button className="ml" httpType="reset" onClick={close}>{ t('dialog-cancel') }</Button>
						<Button type="primary" httpType="submit">{ t('dialog-confirm') }</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</form>
	)
}

export default OrderFormDialog;