import useApp from "contexts/app.context";
import BasicLayout from "layouts/basic.layout";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./article.module.css";

function ArticlePage({ params: { articleCode } }) {
	const app = useApp();
	const { i18n } = useTranslation();

	const { data } = useSWR(`/api/v2/${app.store}/article/${articleCode}`);

	return (
		<BasicLayout>
			<p>{ data?.title[i18n.language] }</p>
			<div className={styles.wrapper} dangerouslySetInnerHTML={{ __html: data?.value[i18n.language] }}></div>
		</BasicLayout>
	)
}

export default ArticlePage;