import BasicLayout from "layouts/basic.layout";
import styles from "./catalog.page.module.css";
import ProductCard from "pages/catalog.page/components/product-card.component";
import TopFilter from "pages/catalog.page/components/top-filter";
import Filter from "./components/filter.component";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import useApp from "contexts/app.context";
import Query from "lib/query";
import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import Loader from "components/loader.component";
import cn from "classnames";
import Button from "components/button.component";
import MdiButton from "components/mdi-button.component";
import { mdiArrowLeft } from "@mdi/js";

function CatalogPage() {

	const app = useApp();
	const [ filter, setFilter ] = useState(Query.parse(window.location));
	const { data: originalData } = useSWR(`/api/v2/${app.store}/product${Query.stringify(filter)}`);
	const { t, i18n } = useTranslation('catalog');
	const [ , navigate] = useLocation();
	const [ filterOpen, setFilterOpen ] = useState(false);

	const [ data, setData ] = useState();

	useEffect(() => {
		if (originalData) {
			setData(originalData);
		}
	}, [ originalData ])

	useEffect(() => {
		if (filterOpen) {
			document.body.style.overflow = "hidden";
			document.body.style.height = "100%";
		} else {
			document.body.style.overflow = "auto";
			document.body.style.height = "auto";
		}
	}, [ filterOpen ]);

	function onFilterToggle(facetCode, optionCode) {
		const newFilter = { ...filter };
		const f = new Set(newFilter[facetCode] || []);
		if (f.has(optionCode)) {
			f.delete(optionCode);
		} else {
			f.add(optionCode);
		}
		if (f.size) {
			newFilter[facetCode] = Array.from(f);
		} else {
			delete newFilter[facetCode];
		}
		setFilter(newFilter);
		const url = app.href('/catalog' + Query.stringify(newFilter));
		navigate(url);
	}

	if (!data) {
		return (
			<BasicLayout fullwidth>
				<center style={{ height: '100%' }}>
					<Loader />
				</center>
			</BasicLayout>
		)
	}


	return (
		<BasicLayout fullwidth>
			<div className={styles.catalog}>
				<Button className={styles.filter_button} onClick={() => setFilterOpen(p => !p)}>{ t('filter') }</Button>
				<div className={cn(styles.backdrop, filterOpen && styles.backdrop__open)} onClick={() => setFilterOpen(false)}></div>
				<Filter className={cn(styles.filter, filterOpen && styles.filter__open)} facets={data?.facets} filter={filter} onFilterToggle={onFilterToggle}>
					<MdiButton className={styles.filter_close} path={mdiArrowLeft} onClick={() => setFilterOpen(false)} />
				</Filter>
				<div style={{ flexGrow: 1 }}>
					<TopFilter facets={data?.facets} filter={filter} onFilterToggle={onFilterToggle} />
					{
						data?.productGroups.map(pg => (
							<React.Fragment key={pg.name[i18n.language]}>
								<h2 className={styles.header}>{ pg.name[i18n.language] }</h2>
								<div className={styles.products}>
									{ pg.products.map(product => <ProductCard key={product.code} className={styles.product} product={product} colorObj={data.color} />) }
								</div>
							</React.Fragment>
						))
					}
				</div>
			</div>
		</BasicLayout>
	)
}

export default CatalogPage;