import styles from "./filter.module.css";
import cn from "classnames";
import CheckboxImage from "components/checkbox-image.component";
import ProductColorBuilder from "lib/product-color-builder";
import Checkbox from "components/checkbox.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Filter({ className, facets, filter, onFilterToggle, children }) {

	const { t, i18n } = useTranslation('catalog');

	const [ expanded, setExpanded ] = useState(new Set());

	function handleChange(facetCode, optionCode) {
		onFilterToggle(facetCode, optionCode);
	}

	function handleToggleShow(facetCode) {
		const newSet = new Set(expanded);
		if (newSet.has(facetCode)) {
			newSet.delete(facetCode);
		} else {
			newSet.add(facetCode);
		}
		setExpanded(newSet);
	}

	return (
		<div className={cn(styles.wrapper, className)}>
			{
				facets
					?.filter(f => f.options.length > 0)
					?.map(f => {
						let showCount = 0;
						const expandable = f.expandableFrom !== 0 && f.expandableFrom < f.options.length;
						if (!expandable) {
							showCount = f.options.length;
						} else {
							showCount = expanded.has(f.code) ? f.options.length : f.expandableFrom;
						}
						
						return (
							<div key={f.code} className={cn(styles.filter_group, styles[`filter_group__${f.type}`])}>
								<h3>{ f.name[i18n.language] }</h3>
								<ul>
									{
										f.options.slice(0, showCount).map(opt => {
											const id = `${f.code}:${opt.code}`;
											const props = {
												id,
												checked: filter?.[f.code]?.includes(opt.code) ?? false,
												onChange: () => handleChange(f.code, opt.code)
											}
											if (f.type === 'checkbox') {
												return (
													<li key={id}>
														<Checkbox {...props}>{ opt.name[i18n.language] }</Checkbox>
													</li>
												)
											}
											if (f.type === 'color') {
												return (
													<li key={id} tooltip={`${opt.code} - ${opt.name[i18n.language]}`}>
														<CheckboxImage {...props} src={ProductColorBuilder.get(opt)} />
													</li>
												)
											}
											if (f.type === 'icon') {
												return (
													<li key={id}>
														<CheckboxImage {...props} src={process.env.REACT_APP_END_POINT + `/image/trademark/logo-${opt.code}-small.svg`} />
													</li>
												)
											}
											return null;
										})
									}
									{ expandable && <li onClick={() => handleToggleShow(f.code)} className={styles.show}>{ t(expanded.has(f.code) ? 'show-less' : 'show-all') }</li> }
								</ul>
							</div>
						)
					}
				)
			}
			{ children }
		</div>
	)
}

export default Filter;