import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./top-filter.module.css";

function TopFilter({ facets, filter, onFilterToggle }) {
	const { t, i18n } = useTranslation('catalog');

	const results = useMemo(() => {
		return Object.entries(filter)
			.map(([ facetCode, optionsCodes ]) => {
				const facet = facets?.find(f => f.code === facetCode);
				if (!facet) {
					return null;
				}
				const options = optionsCodes
					.map(oc => facet.options.find(o => o.code === oc))
					.filter(e => e)

				return {
					name: facet.name[i18n.language],
					code: facetCode,
					options: options.map(o => ({
						code: o.code,
						name: o.name[i18n.language]
					}))
				}
			})
			.filter(e => e)

	}, [ facets, filter, i18n.language ]);

	if (!results || !results.length) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{
					results?.map(facet => (
						<Fragment key={facet.code}>
							{ <span className={styles.header}>{ facet.name }: </span> }
							{ facet.options.map(opt => <button key={`${facet.code}:${opt.code}`} className={styles.tag} onClick={() => onFilterToggle(facet.code, opt.code)}><span>{ opt.name }</span>✖</button>) }
						</Fragment>
					))
				}
			</div>
			<button className={styles.clear}><span>{ t('clear-filter') }</span>✖</button>
		</div>
	)
}

export default TopFilter;