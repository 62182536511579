import styles from "./product-card.module.css";
import cn from "classnames";
import ProductImageBuilder from "lib/product-image-builder";
import { useMemo, useState } from "react";
import ProductColorBuilder from "lib/product-color-builder";
import Random from "lib/random";
import Link from "components/link.component";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";

function ProductCard({ product, className, colorObj }) {

	const [ selectedColorCode, setSelectedColorCode ] = useState(product.colors[Random.integer(0, product.colors.length)]);
	const selectedColor = useMemo(() => colorObj[selectedColorCode], [ selectedColorCode, colorObj ]);
	const { i18n } = useTranslation();
	const onlyDesktop = useMedia('(min-width: 992px)');

	const href = useMemo(() => `/${product.seoName}/${selectedColor?.urlCode}`, [ product, selectedColor ]);

	return (
		<Link href={href}>
			<div className={cn(styles.wrapper, className)}>
				<img loading="lazy" {...ProductImageBuilder.makeSrcSet({ ...product, color: selectedColor?.urlCode }, { view: 'c', width: 164 })} alt={`${product.seoName} ${product.name[i18n.language]}`}/>
				<p className={styles.title}><span className={styles.code}>{ product.code }</span><span className={styles.name}>{ product.name[i18n.language] }</span></p>
				<p className={styles.subtitle}>{ product.subName[i18n.language] }</p>
				{
					onlyDesktop && (
						<ul className={styles.colors}>
							{
								product.colors.map(colorCode => {
									const c = colorObj[colorCode];
									if (c) {
										return (
											<li tooltip={`${c.code} - ${c.name[i18n.language]}`} key={c.code}><img onMouseEnter={() => setSelectedColorCode(c.urlCode)} src={ProductColorBuilder.get(c)} alt={`${c.name[i18n.language]} color`} /></li>
										)
									} else {
										return null;
									}
								})
							}
						</ul>
					)
				}
			</div>
		</Link>
	)
}

export default ProductCard;