class Query {
	static stringify(params) {
		if (params) {
			const q = Object
				.entries(params)
				.map(([ key, value ]) => {
					if (Array.isArray(value)) {
						if (value.length) {
							return `${key}=${value.join(';')}`
						}
						return undefined;
					} else {
						return `${key}=${value}`
					}
				})
				.filter(e => e)
				.join('&');
			return q ? ('?' + q) : '';
		} else {
			return ''
		}
	}

	static parse(url) {
		const u = new URL(url);
		const params = {};
		for (const [ key, value ] of u.searchParams) {
			if (value) {
				params[key] = value.split(';');
			}
		}
		return params;
	}
}

export default Query;