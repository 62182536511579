import { mdiCartOff } from "@mdi/js";
import cn from "classnames";
import Button from "components/button.component";
import MdiButton from "components/mdi-button.component";
import useApp from "contexts/app.context";
import useCart from "contexts/cart.context";
import useDialog from "contexts/dialog.context";
import BasicLayout from "layouts/basic.layout";
import fetcher from "lib/fetcher";
import findPrice from "lib/find-price";
import generateDemandEmail from "lib/generate-demand-email";
import Query from "lib/query";
import OrderFormDialog from "pages/cart.page/components/order-form.dialog";
import ProductCart from "pages/cart.page/components/product-cart";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useLocation } from "wouter";

import styles from "./cart.page.module.css";

function CartPage() {
	
	const { t, i18n } = useTranslation('cart');
	const { t: tMail } = useTranslation('demand-email');
	const app = useApp();
	const cart = useCart();
	const dialog = useDialog();
	const [ , setLocation] = useLocation();

	const items = useMemo(() => {
		if (cart?.items) {
			return Object.values(cart.items);
		}
		return []
	}, [ cart ])

	const { data: priceDataMap } = useSWR(!!items.length && app.currency && `/api/v2/${app.store}/demand/price` + Query.stringify({ currency: app.currency, productSizeCode: items.map(i => i.productSizeCode).join(',') }))

	useEffect(() => {
		if (!cart) {
			setLocation(app.href('/'));
		}
	}, [ cart, setLocation, app ])

	const price = useMemo(() => {
		let total = 0;
		let totalWithVat = 0;
		if (cart?.items && priceDataMap) {
			for (const { quantity, productSizeCode } of Object.values(cart.items)) {
				const availability = priceDataMap[productSizeCode];
				const price = findPrice(availability.prices, quantity);
				total += (price?.value || 0) * quantity;
				totalWithVat += (price?.valueWithVat || 0) * quantity;
			}
		}
		return { total, totalWithVat }
	}, [ cart.items, priceDataMap ]);



	function handleClear() {
		dialog.confirm(t('clear-cart') + '?', (result) => {
			if (result) {
				cart.clear();
			}
		})
	}

	function handleSend() {
		dialog.show(OrderFormDialog, {
			onSubmit: async (data) => {
				try {
					const html = generateDemandEmail(data, items, priceDataMap, price, i18n.language, tMail);
					await fetcher(`/api/v2/${app.store}/demand`, { method: 'POST', body: { content: html, subject: tMail('subject') } });
					dialog.message(t('order-success'));
					cart.clear();
					setLocation(app.href('/'));
				} catch (err) {
					dialog.message(t('order-error'));
					throw err;
				}
			}
		})
	}

	return (
		<BasicLayout>
			<div className="fx between px-1">
				<h1>{ t('header') }</h1>
				<MdiButton type="dangerous" path={mdiCartOff} title={t('clear-cart')} onClick={handleClear} />
			</div>
			<div>
				{	items?.map((p) => <ProductCart key={p.productSizeCode} {...p} availability={priceDataMap?.[p.productSizeCode]} />)	}
			</div>
			<div className="my-4">
				{ !!price.total && <div className={cn("py-1 fx between bold", { [styles.price_both]: !!price.totalWithVat })}><span>{ t('total-without-vat') }</span><span>{ price.total.toFixed(2) } { app.currency }</span></div> }
				{ !!price.totalWithVat && <div className="py-1 fx between bold"><span>{ t('total') }</span><span>{ price.totalWithVat.toFixed(2) } { app.currency }</span></div> }
			</div>
			<p className={styles.warning}>{ t('warning') }</p>
			<div className="my-4">
				<Button disabled={!items?.length} type="primary" style={{ width: '100%' }} onClick={handleSend}>{ t('create-order') }</Button>
			</div>
		</BasicLayout>
	)
}

export default CartPage;