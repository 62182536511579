import { mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import useDialog from "contexts/dialog.context";
import ProductImageBuilder from "lib/product-image-builder";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import GalleryDialog from "./gallery.dialog";
import styles from "./gallery.module.css";

function Gallery({ product, colorCode }) {
	const [ selectedView, setSelectedView ] = useState('c');
	const { t } = useTranslation('detail');
	const dialog = useDialog();
	const onlyDesktop = useMedia('(min-width: 992px)');

	function showGallery() {
		dialog.show(GalleryDialog, { product, color: colorCode, view: selectedView });
	}

	return (
		<div onClick={showGallery}>
			<img className={styles.image} {...ProductImageBuilder.makeSrcSet({ code: product?.code, color: colorCode }, { view: selectedView, width: 400 })} alt="" />
			{
				onlyDesktop && (
					<ul className={styles.thumbs}>
						{ product?.views.slice(0, 3).map(view => <li onMouseEnter={() => setSelectedView(view)} key={view}><img {...ProductImageBuilder.makeSrcSet({ code: product?.code, color: colorCode}, { view, width: 92 })} alt="" /></li>) }
						<li>
							<div className={styles.show_more} onClick={showGallery}>
								<Icon path={mdiPlusCircle} />
								<p>{ `${t('show-all')} (${product?.views?.length ?? 3})` }</p>
							</div>
						</li>
					</ul>
				)
			}
		</div>
	)
}

export default Gallery;