import Loader from "components/loader.component";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useLocation } from "wouter";

/**
 * @typedef AppContextState
 * @type {object}
 * @property {string} currency
 * @property {(newCurrency: string) => void} setCurrency
 * @property {string} store
 * @property {(path: string, lang: string) => string} href
 * @property {any} error
 * @property {{
 * 	languages: string[],
 * 	currencies: string[],
 * 	design: {
 * 		logoPath: string,
 * 		faviconPath: string,
 * 		color: string
 * 	},
 * 	articles: [{
 * 		title: object,
 * 		code: string,
 * 		displayOrder: number
 * 	}],
 * 	mainContent: [{
 * 		code: string,
 * 		value: object
 * 	}],
 * 	banners: [{
 * 		link: string,
 * 		path: string,
 * 		isActive: boolean,
 * 		language: string
 * 	}],
 * 	price: {
 * 		isLimitDisplayed: boolean,
 * 		isRoundingForVat: boolean,
 * 		showPrice: "WithoutPrice" | "ExcludeVat" | "AddVat" | "Both"
 * 	},
 * 	socialMedia: {
 * 		facebookUrl: string,
 * 		gPlusUrl: string,
 * 		googleAnalyticsBasicCode: string,
 * 		googleAnalyticsComplexCode: string,
 * 		twitterUrl: string,
 * 		instagramUrl: string,
 * 		mapFrame: string
 * 	},
 * 	isDemandEnabled: boolean
 * }} layout
*/

/** @type {AppContextState} */
const initState = {};

const AppContext = createContext(initState);

function parseStore() {
	let [ , storeString, ] = window.location.pathname.split('/');
	const store = storeString || 'default';
	return store;
}

export function AppContextProvider({ children }) {

	const store = parseStore();
	const [ currency, setCurrency ] = useState(undefined);
	const { t, i18n } = useTranslation();
	const { data: layout, error } = useSWR(() => store && `/api/v2/${store}/layout`);
	const [ loc ] = useLocation();

	useEffect(() => {
		const [ , , lang] = window.location.pathname.split('/');
		if (layout) {
			if (!layout.languages.includes(lang)) {
				const redirect = `/${store}/${layout.languages[0] ?? 'en'}`;
				window.location = redirect;
			}
		}
		if (lang !== i18n.language) {
			i18n.changeLanguage(lang);
		}
	}, [ loc, i18n, layout, store ])

	useEffect(() => {
		if (!currency && layout && layout.currencies) {
			setCurrency(layout.currencies[0]);
		}
	}, [ layout, currency ])

	useEffect(() => {
		if (layout?.design) {
			document.getElementById('favicon').setAttribute('href', process.env.REACT_APP_END_POINT + '/image/' + layout.design.faviconPath)
			document.body.style.setProperty('--color-primary', layout.design.color);
		}
	})
	
	const href = useCallback((path, lang) => {
		return `/${store}/${lang || i18n.language}` + path
	}, [ store, i18n.language ]);

	if (error) {
		return <center><h1>Error</h1></center>
	}

	if (!layout) {
		return (
			<center>
				<Loader />
			</center>
		)
	}

	if (!layout.design) {
		return (
			<center>
				<h1>{ t('online-catalog-not-found') }</h1>
			</center>
		);
	}

	return (
		<AppContext.Provider
			value={{
				store: store,
				currency,
				setCurrency,
				href,
				layout,
				error
			}}
		>
			{ children }
		</AppContext.Provider>
	)
}

export default function useApp() {
	const dialog = useContext(AppContext);
	return dialog;
}