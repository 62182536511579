import Query from "./query";

export const END_POINT = process.env.REACT_APP_END_POINT;

/**
 * @param {string} url
 * @param {Object} params 
 * @param {"GET" | "POST" | "PATCH" | "PUT" | "DELETE"} [params.method]
 * @param {object} [params.params]
 */
export default async function fetcher(url, { method = 'GET', params, body } = {}) {
	const uriQuery = params ? Query.stringify(params) : '';
	const finalUrl = END_POINT + url + uriQuery;
	const response = await fetch(finalUrl, {
		method,
		credentials: 'include',
		headers: {
			"accept": "application/json",
			"content-type": body instanceof FormData ? undefined : "application/json",
		},
		body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined
	})
	if (!response.ok) {
		throw new Error(response.status + " - " + response.statusText);
	}

	if (response.status !== 204) {
		try {
			const body = await response.json();
			return body;
		} catch (err) {
			console.warn('Response JSON parse error!')
			return null;
		}
		// return {
		// 	content: body,
		// 	pagination: {
		// 		current: parseInt(response.headers.get("X-Page-Index")) + 1,
		// 		pageSize: parseInt(response.headers.get("X-Page-Size")),
		// 		total: parseInt(response.headers.get("X-Total")),
		// 	}
		// }
	}
	return null;
}
