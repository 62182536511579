import Button from "components/button.component";
import { Icon } from '@mdi/react';
import { mdiFile } from "@mdi/js/mdi";
import styles from "./buttons.module.css";
import { END_POINT } from "lib/fetcher";
import { useTranslation } from "react-i18next";

function Buttons({ product }) {

	const { userInformationPdf, sizeChartPdf, technicalSpecificationPdf, certificationPdf, declarationPdf, additionalInformationPdf, productCards } = product;

	const { t, i18n } = useTranslation('detail');
	const lang = i18n.language;

	return (
		<div className={styles.list}>
			{ userInformationPdf && <Button type="white" component="a" href={`${END_POINT}/file/${userInformationPdf}`}><Icon path={mdiFile} color="red"/>{t('user-information-pff')}</Button> }
			{ sizeChartPdf && <Button type="white" component="a" href={`${END_POINT}/file/${sizeChartPdf}`}><Icon path={mdiFile} color="red"/>{t('size-chart-pdf')}</Button> }
			{ productCards?.[lang] && <Button type="white" component="a" href={`${END_POINT}/file/${productCards[lang]}`}><Icon path={mdiFile} color="red"/>{t('product-card-pdf')}</Button> }
			{ technicalSpecificationPdf && <Button type="white" component="a" href={`${END_POINT}/file/${technicalSpecificationPdf}`}><Icon path={mdiFile} color="red"/>{t('technical-specification-pdf')}</Button> }
			{ certificationPdf && <Button type="white" component="a" href={`${END_POINT}/file/${certificationPdf}`}><Icon path={mdiFile} color="red"/>{t('certification-pdf')}</Button> }
			{ declarationPdf && <Button type="white" component="a" href={`${END_POINT}/file/${declarationPdf}`}><Icon path={mdiFile} color="red"/>{t('declaration-pdf')}</Button> }
			{ additionalInformationPdf && <Button type="white" component="a" href={`${END_POINT}/file/${additionalInformationPdf}`}><Icon path={mdiFile} color="red"/>{t('additional-information-pdf')}</Button> }
		</div>
	)
}

export default Buttons;