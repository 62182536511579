import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import styles from "./dropdown.module.css";

function Dropdown({ children, overlay, isArrow = true }) {
	return (
		<span className={styles.wrapper}>
			{ children }
			{ isArrow && <Icon className={styles.arrow} path={mdiChevronDown} /> }
			<div className={styles.dropdown}>{ overlay }</div>
		</span>
	)
}

export default Dropdown;