// FIX ME - sprite cache
const COLOR_SPRITE_SRC = process.env.REACT_APP_END_POINT + '/image/color/sprite.png?r=' + Date.now();
const COLOR_SPRITE_WIDTH = 32;
const COLOR_SPRITE_HEIGHT = 32;
const COLOR_SPRITE_COLUMNS = 16;


class ProductColorBuilder {
	static sprite = new Image();
	static canvas = document.createElement('canvas');
	static err = false;

	static cache = new Map();
	
	static async init() {
		await ProductColorBuilder._download();
		await ProductColorBuilder._render();
	}
	
	static _download() {
		ProductColorBuilder.canvas.width = COLOR_SPRITE_WIDTH;
		ProductColorBuilder.canvas.height = COLOR_SPRITE_HEIGHT;
		return new Promise((resolve, reject) => {
			ProductColorBuilder.sprite.crossOrigin = "Anonymous";
			ProductColorBuilder.sprite.src = COLOR_SPRITE_SRC;
			ProductColorBuilder.sprite.onload = () => {
				resolve();
			}
			ProductColorBuilder.sprite.onerror = (err) => {
				ProductColorBuilder.err = true;
				resolve();
			}
		})
	}

	static async _render() {
		const l = ProductColorBuilder.sprite.height / COLOR_SPRITE_HEIGHT * COLOR_SPRITE_COLUMNS;
		const promises = new Array(l).fill(true).map((_, i) => ProductColorBuilder._makeImgSrc(i));
		return await Promise.all(promises);
	}

	static get(color) {
		if (ProductColorBuilder.err) {
			return '';
		}
		if (ProductColorBuilder.cache.has(color.displayOrder)) {
			return ProductColorBuilder.cache.get(color.displayOrder)
		}
	}

	static async _makeImgSrc(position = 0) {
		return new Promise((resolve, reject) => {
			const ctx = ProductColorBuilder.canvas.getContext('2d');
			const x = position % COLOR_SPRITE_COLUMNS;
			const y = Math.trunc(position / COLOR_SPRITE_COLUMNS);
	
			ctx.clearRect(0,0, COLOR_SPRITE_WIDTH, COLOR_SPRITE_HEIGHT);
			ctx.drawImage(
				ProductColorBuilder.sprite,
	
				x * COLOR_SPRITE_WIDTH,
				y * COLOR_SPRITE_HEIGHT,
				COLOR_SPRITE_WIDTH,
				COLOR_SPRITE_HEIGHT,
	
				0,
				0,
				COLOR_SPRITE_WIDTH,
				COLOR_SPRITE_HEIGHT
	
			);
			ProductColorBuilder.canvas.toBlob(b => {
				const url = URL.createObjectURL(b);
				this.cache.set(position, url);
				resolve()
			});
		})
	}
}

export default ProductColorBuilder;