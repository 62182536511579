import Icon from "@mdi/react";
import cn from "classnames";
import styles from "./mdi-button.module.css";

/**
 * 
 * @param {object} props
 * @param {string} props.path 
 * @param {string} props.className
 * @param {"primary" | "dangerous"} props.type
 * @returns 
 */
function MdiButton({ path, className, type, ...rest }) {
	return (
		<button className={cn(styles.wrapper, className, styles[`type_${type}`])} {...rest}>
			<Icon path={path} />
		</button>
	)
}

export default MdiButton;