import styles from "./modal.module.css";
import cn from "classnames";
import MdiButton from "components/mdi-button.component";
import { mdiClose } from "@mdi/js";
import { useEffect } from "react";

function Modal({ children, open, className, style }) {

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden";
			document.body.style.height = "100%";
		} else {
			document.body.style.overflow = "auto";
			document.body.style.height = "auto";
		}
	}, [ open ])

	return (
		<div className={cn(styles.wrapper, !open && styles.close)}>
			<div className={cn(styles.body,className)} style={style}>
				{ children }
			</div>
		</div>
	)
}

function Header({ children, className, onClose, ...rest }) {
	return (
		<div className={cn(styles.header, className)} {...rest}>
			{ children }
			{ onClose && <MdiButton path={mdiClose} onClick={onClose} className={styles.header_close} /> }
		</div>
	)
}

function Content({ children, className, ...rest }) {
	return (
		<div className={cn(styles.content, className)} {...rest}>
			{ children }
		</div>
	)
}

function Footer({ children, className, ...rest }) {
	return (
		<div className={cn(styles.footer, className)} {...rest}>
			{ children }
		</div>
	)
}

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;