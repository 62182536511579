import cn from 'classnames';
import styles from './badge.module.css';

function Badge({ children, value, className }) {
	return (
		<span className={styles.wrapper}>
			{ children }
			<span className={cn(styles.value, className)}>{ value }</span>
		</span>
	)
}

export default Badge;