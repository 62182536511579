import { Children, useEffect, useRef, useState } from "react"
import styles from "./carousel.module.css"
import cn from "classnames"

const AUTOPLAY_INTERVAL = 6000;

function calcNextIndex(prev, step, total) {
	return Math.max((prev + step), 0) % total;
}

export default function Carousel({ children, className }) {

	const [ index, setIndex ] = useState(0);
	const childs = Children.toArray(children);
	const intervalRef = useRef(undefined);

	const isArrowsVisible = childs.length > 1;

	function next(step) {
		setIndex(prev => calcNextIndex(prev, step, childs.length));
		play();
	}

	function play() {
		clearInterval(intervalRef.current);
		intervalRef.current = setInterval(() => {
			next(1);
		}, AUTOPLAY_INTERVAL);
	}

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setIndex(prev => calcNextIndex(prev, 1, childs.length));
		}, AUTOPLAY_INTERVAL);

		return () => {
			clearInterval(intervalRef.current);
		}
	}, [ childs.length ]);


	return (
		<div className={cn(styles.wrapper, className)}>
			<div className={styles.film} style={{
				transform: `translateX(-${100 * index}%)`
			}}>
				{ children }
			</div>
			{
				isArrowsVisible && (
					<>
						<button className={cn(styles.arrow, styles.left)} onClick={() => next(-1)} />
						<button className={cn(styles.arrow, styles.right)} onClick={() => next(1)} />
						<div className={styles.dots}>
							{ childs.map((_, i) => <button key={i} className={cn(styles.dot, index === i && styles.active)} onClick={() => setIndex(i)} />) }
						</div>
					</>
				)
			}
		</div>
	)
}