import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import useApp from "contexts/app.context";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./search-bar.module.css";
import cn from 'classnames';
import { Link } from "wouter";
import useDebounceValue from "hooks/use-debounced-value.hook";
import { useClickAway } from 'react-use';

function Row({ code, name, colors }) {
	const { i18n } = useTranslation();
	const app = useApp();
	return (
		<Link className={styles.row} href={app.href(`/${code.toLowerCase()}/${colors?.[0]}`)}>
			<span className={styles.row_code}>{ code }</span><span className="mx-1">-</span><span>{name[i18n.language]}</span>
		</Link>
	)
}

function SearchBar({ style }) {

	const app = useApp();
	const { t } = useTranslation();

	const [ searchTerm, setSearchTerm ] = useState('');
	const debouncedSearchTerm = useDebounceValue(searchTerm, 1000);

	const [ open, setOpen ] = useState(false)

	const { data } = useSWR(() => debouncedSearchTerm && `/api/v2/${app.store}/product/search/${debouncedSearchTerm}`);

	useEffect(() => {
		if (data) {
			setOpen(true);
		}
	}, [ data ])

	function handleFocus() {
		if (data?.length) {
			setOpen(true)
		}
	}

	const ref = useRef(null);
	useClickAway(ref, () => {
		setOpen(false);
	});

	return (
		<div className={styles.wrapper} style={style} ref={ref}>
			<div className={styles.search}>
				<Icon path={mdiMagnify} color="var(--color-light)" />
				<input className={styles.input} onFocus={handleFocus} placeholder={t('search')} type="text" value={searchTerm} onChange={({ target }) => setSearchTerm(target.value)} />
			</div>
			<div className={cn(styles.results, { [styles.results_open]: open })}>
				{ data?.map(res => <Row key={res.code} {...res} />) }
			</div>
		</div>
	)
}

export default SearchBar;