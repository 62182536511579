import Button from "components/button.component";
import Modal from "components/modal.component";
import ProductColorBuilder from "lib/product-color-builder";
import ProductImageBuilder from "lib/product-image-builder";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./gallery.dialog.module.css";
import cn from "classnames";

function GalleryDialog({ open, close, product, color: parentColor, view: parentView }) {
	const { t, i18n } = useTranslation('detail');
	const [ color, setColor ] = useState(parentColor);
	const [ view, setView ] = useState(parentView);

	const viewIndex = useMemo(() => product.views.findIndex(v => v === view), [ view, product.views ])

	const next = useCallback((step) => {
		if (viewIndex + step < 0) {
			setView(product.views[product.views.length - 1]);
		} else {
			const newIndex = (viewIndex + step) % product.views.length;
			setView(product.views[newIndex]);
		}
	}, [ product.views, viewIndex ]);

	return (
		<Modal open={open} style={{ minWidth: 'calc(100% / 1.618)' }}>
			<Modal.Header onClose={close}>{ `${product.name[i18n.language]} ${product.code}` }</Modal.Header>
			<Modal.Content>
				<ul className={styles.colors}>
					{
						product.colors.map(c => (
							<li key={c.code} className={styles.color} tooltip={c.name[i18n.language]} onClick={() => setColor(c.code)}>
								<img src={ProductColorBuilder.get(c)} alt=""/>
								<span style={{ color: c.textColor }}>{ c.code }</span>
							</li>
						))
					}
				</ul>
				<hr/>
				<div className={styles.view}>
					<button className={cn(styles.arrow, styles.left)} onClick={() => next(-1)} />
					<img className={styles.image} {...ProductImageBuilder.makeSrcSet({ code: product.code, color }, { view, width: 400 })} alt="" />
					<button className={cn(styles.arrow, styles.right)} onClick={() => next(1)} />
				</div>
				<hr/>
				<ul className={styles.thumbs}>
					{ product.views.map(view => <li key={view}><img onMouseEnter={() => setView(view)} {...ProductImageBuilder.makeSrcSet({ code: product.code, color}, { view, width: 92 })} alt="" /></li>) }
				</ul>
			</Modal.Content>
			<Modal.Footer className="f">
				<Button className="ml" onClick={close}>{ t('close') }</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default GalleryDialog;