import DetailPage from "pages/detail.page"
import { Route, Switch } from "wouter"
import HomePage from "pages/index.page"
import CatalogPage from "pages/catalog.page";
import useApp from "contexts/app.context";
import ArticlePage from "pages/article.page";
import NotFoundPage from "pages/not-found.page";
import CartPage from "pages/cart.page";

function App() {

  useApp();

  return (
    <Switch>
      <Route path="/:store/:lang" component={HomePage} />
      <Route path="/:store/:lang/catalog" component={CatalogPage} />
      <Route path="/:store/:lang/article/:articleCode" component={ArticlePage} />
      <Route path="/:store/:lang/:productCode/:colorCode" component={DetailPage} />
      <Route path="/:store/:lang/cart" component={CartPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default App;
