function injectCss(css) {
	const style = document.createElement('style');
	style.setAttribute('type', 'text/css');
	style.innerHTML = css;
	document.getElementsByTagName('head')[0].appendChild(style);
	return () => style.remove();
}

class Css {

	static createDefault({ max = 6, step = 0.5, unit = 'rem' } = {}) {
		Css
			.createMargin({ max, step, unit })
			.createPadding({ max, step, unit })
			.createFlex({ flexMax: max, gapMax: max, gapStep: step, gapUnit: unit })
		return Css;
	}

	static createPadding({ max, step, unit }) {
		let css = '';
		for(let i = 0; i <= max; i++) {
			const value = (i * step) + unit;
			css += `
				.p-${i} { padding: ${value} }
				.px-${i} { padding-right: ${value}; padding-left: ${value} }
				.py-${i} { padding-top: ${value}; padding-bottom:${value} }
				.pt-${i} { padding-top: ${value} }
				.pr-${i} { padding-right: ${value} }
				.pb-${i} { padding-bottom: ${value} }
				.pl-${i} { padding-left: ${value} }
				.p-${i}-imp { padding: ${value} !important }
				.px-${i}-imp { padding-right: ${value} !important; padding-left: ${value} !important }
				.py-${i}-imp { padding-top: ${value} !important; padding-bottom :${value} !important }
				.pt-${i}-imp { padding-top: ${value} !important }
				.pr-${i}-imp { padding-right: ${value} !important }
				.pb-${i}-imp { padding-bottom: ${value} !important }
				.pl-${i}-imp { padding-left: ${value} !important }
			`
		}
		injectCss(css)
		return Css;
	}
	static createMargin({ max, step, unit }) {
		let css = `
			.m { margin: auto }
			.mx { margin-right: auto; margin-left: auto }
			.my { margin-top: auto; margin-bottom: auto }
			.mt { margin-top: auto }
			.mr { margin-right: auto }
			.mb { margin-bottom: auto }
			.ml { margin-left: auto }
			.m-imp { margin: auto !important }
			.mx-imp { margin-right: auto !important; margin-left: auto !important }
			.my-imp { margin-top: auto !important; margin-bottom: auto !important }
			.mt-imp { margin-top: auto !important }
			.mr-imp { margin-right: auto !important }
			.mb-imp { margin-bottom: auto !important }
			.ml-imp { margin-left: auto !important }
		`
		for(let i = 0; i <= max; i++) {
			const value = (i * step) + unit;
			css += `
				.m-${i} { margin: ${value} }
				.mx-${i} { margin-right: ${value}; margin-left: ${value} }
				.my-${i} { margin-top: ${value}; margin-bottom: ${value} }
				.mt-${i} { margin-top: ${value} }
				.mr-${i} { margin-right: ${value} }
				.mb-${i} { margin-bottom: ${value} }
				.ml-${i} { margin-left: ${value} }
				.m-${i}-imp { margin: ${value} !important }
				.mx-${i}-imp { margin-right: ${value} !important; margin-left: ${value} !important }
				.my-${i}-imp { margin-top: ${value} !important; margin-bottom: ${value} !important }
				.mt-${i}-imp { margin-top: ${value} !important }
				.mr-${i}-imp { margin-right: ${value} !important }
				.mb-${i}-imp { margin-bottom: ${value} !important }
				.ml-${i}-imp { margin-left: ${value} !important }
			`
		}
		injectCss(css)
		return Css;
	}
	static createFlex({ flexMax, gapMax, gapStep, gapUnit }) {
		let css = `
			.fx { display: flex; align-items: center }
			.fx.col { flex-direction: column }
			.fx.left { justify-content: flex-start }
			.fx.right { justify-content: flex-end }
			.fx.center { justify-content: center }
			.fx.between { justify-content: space-between }
			.fx.top { align-items: flex-start }
			.fx.bottom { align-items: flex-end }
			.fx.stretch { align-items: stretch }
			.fx.wrap { flex-wrap: wrap }
		`
		for (let i = 0; i <= flexMax; i++) {
			css += `
				.fx > .grow-${i} { flex-grow: ${i} }
				.fx > .shrink-${i} { flex-shrink: ${i} }
			`
		}
		for(let i = 0; i <= gapMax; i++) {
			const value = (i * gapStep) + gapUnit;
			css += `.fx.gap-${i} { gap: ${value} }`
		}
		injectCss(css)
		return Css;
	}

	static createFlexBreak(minWidth) {
		const css = `
			.fx.responsive {
				flex-direction: column;
			}
			@media only screen and (min-width: ${minWidth}) {
				.fx.responsive {
					flex-direction: row;
				}
			}`;
		injectCss(css);
		return Css;
	}

	static createVh() {
		function setVh() {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		window.addEventListener('resize', setVh);
		setVh();
		return Css;
	}

	static createText() {
		const css = `
			.bold { font-weight: 600 }
			.t-left { text-align: left }
			.t-center { text-align: center }
			.t-right { text-right: right }
			.t-block { text-block: block }
		`
		injectCss(css);
		return Css;
	}
}

export default Css
