import styles from './not-found.module.css';

function NotFoundPage() {
	return (
		<div className={styles.wrapper}>
			<h1>Page Not Found!</h1>
		</div>
	)
}

export default NotFoundPage;